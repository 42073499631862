import React from 'react';
import { Grid, Typography, Box, Divider } from '@material-ui/core';
// import { useMediaQuery } from '@material-ui/core';
// import { useTheme } from '@material-ui/core/styles';

export default function SectionContainerLayout(props) {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    // TODO:  data-sal='zoom-in' data-sal-duration='1000'
    <Box mt={5}>
      <Grid container spacing={3}>
        {props.title !== '' && (
          <Grid item xs={12}>
            <Typography variant='h4' color='primary' gutterBottom className='text-uppercase'>
              <Box fontWeight='fontWeightBold'>{props.title}</Box>
            </Typography>

            <Divider />
          </Grid>
        )}

        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </Box>
  );
}
