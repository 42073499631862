import * as Yup from 'yup';
import checkoutFormModel from './checkout-form-model';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const {
  formField: {
    salutation,
    firstName,
    lastName,
    email,
    confirmedEmail,
    country,
    nationality,
    // countryCode,
    mobile,
    jobTitle,
    jobRole,
    jobRoleSpecify,
    industrySectorSpecify,
    company,
    industrySector,
    events,
    // accreditedMedia,
    recieveMoreInfo,
    conditions,
  },
} = checkoutFormModel;

export default [
  Yup.object().shape({
    [salutation.name]: Yup.object().required(`${salutation.requiredErrorMsg}`),
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [email.name]: Yup.string().required(`${email.requiredErrorMsg}`).email(`${email.invalidErrorMsg}`),
    [confirmedEmail.name]: Yup.string()
      .email(`${confirmedEmail.invalidErrorMsg}`)
      .required(`${confirmedEmail.requiredErrorMsg}`)
      .oneOf([Yup.ref('email'), null], 'Email must match'),
    [country.name]: Yup.object().required(`${country.requiredErrorMsg}`),
    [nationality.name]: Yup.object().required(`${nationality.requiredErrorMsg}`),
    // [countryCode.name]: Yup.string().required(`${countryCode.requiredErrorMsg}`),
    [mobile.name]: Yup.string()
      .required(`${mobile.requiredErrorMsg}`)
      .matches(phoneRegExp, `${mobile.invalidErrorMsg}`),
  }),
  Yup.object().shape({
    [jobTitle.name]: Yup.string()
      .test('len', 'Must be exactly 40 characters', (val) => (val ? val.length <= 40 : true))
      .required(`${jobTitle.requiredErrorMsg}`),
    [jobRole.name]: Yup.object().required(`${jobRole.requiredErrorMsg}`), // o
    // [jobRoleSpecify.name]: Yup.string() // s
    //   // .shape({ code: '10', name: 'Other' }),
    //   .test(jobRole.name, 'llllllllll', function () {
    //     const tttt = this.parent.jobRole !== { code: '10', name: 'Other' };

    //     return tttt;
    //   }),
    // .required(`${jobRoleSpecify.requiredErrorMsg}`),
    // .required(),
    // .ensure()
    // .when(jobRole.name, {
    //   is: { code: '0', name: 'Other' },
    //   then: Yup.string().required(),
    // }),

    // [jobRoleSpecify.name]: Yup.string().required(`${jobRoleSpecify.requiredErrorMsg}`),
    [industrySector.name]: Yup.object().required(`${industrySector.requiredErrorMsg}`),

    // [industrySectorSpecify.name]: Yup.string().required(`${industrySectorSpecify.requiredErrorMsg}`),
    // [industrySectorSpecify.name]: Yup.object()
    //   // .ensure()
    //   .when(industrySector.name, {
    //     is: { code: '42', name: 'Other' },
    //     then: Yup.string().isValidSync(),
    //   }),

    [company.name]: Yup.string().required(`${company.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    // [events.name]: Yup.array().of(Yup.string()).min(1, 'Event is required'),
    // [events.name]: Yup.boolean().oneOf([true], `${events.requiredErrorMsg}`),
    // [accreditedMedia.name]: Yup.boolean(),
    [recieveMoreInfo.name]: Yup.boolean(),
    [conditions.name]: Yup.boolean().oneOf([true], `${conditions.requiredErrorMsg}`),
  }),
];
