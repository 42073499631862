import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import { TextField, InputAdornment, Box, makeStyles, createStyles, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) =>
  createStyles({
    textFieldLabelSpacing: {
      '& .MuiFormLabel-root': {
        marginLeft: '10px',
      },
      '& .MuiAutocomplete-inputRoot': {
        paddingRight: '0px',
      },
    },
  })
);

export default function SearchSelectField({ errorText, data, required, specifyIndustrySectorCallback, ...rest }) {
  // const { errorText, data, ...rest } = props;
  const [field, meta, helpers] = useField({ errorText, data, ...rest });
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (specifyIndustrySectorCallback) {
      if (field.value.name === 'Other') {
        specifyIndustrySectorCallback(true);
      }
      if (field.value.name !== 'Other') {
        specifyIndustrySectorCallback(false);
      }
    }
  });

  return (
    <Box mb={3}>
      <Box clone width={1} p={2}>
        <Autocomplete
          id='country-select-demo'
          value={value ? value : field.value}
          name={field.name}
          options={data}
          forcePopupIcon={false}
          getOptionLabel={(option) => (option.name ? option.name : '')}
          onBlur={() => helpers.setTouched({ [field.name]: true })}
          onChange={(event, newValue) => {
            setValue(newValue);
            helpers.setValue(newValue !== null && newValue !== undefined ? newValue : '');
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              className={classes.textFieldLabelSpacing}
              error={meta.touched && meta.error ? true : false}
              helperText={meta.touched && meta.error ? meta.error : ''}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon fontSize='small' color='primary' />
                    {required && (
                      <>
                        <Typography> * </Typography>
                        <Box pr={1} />
                      </>
                    )}
                  </InputAdornment>
                ),
              }}
              {...rest}
            />
          )}
        />
      </Box>
    </Box>
  );
}
