import checkoutFormModel from './checkout-form-model';
const {
  formField: {
    salutation,
    firstName,
    lastName,
    email,
    confirmedEmail,
    country,
    nationality,
    countryCode,
    mobile,
    jobTitle,
    jobRole,
    jobRoleSpecify,
    industrySector,
    industrySectorSpecify,
    company,
    events,
    // accreditedMedia,
    recieveMoreInfo,
    conditions,
  },
} = checkoutFormModel;

export default {
  [salutation.name]: '',
  [firstName.name]: '',
  [lastName.name]: '',
  [email.name]: '',
  [confirmedEmail.name]: '',
  [country.name]: '',
  [nationality.name]: '',
  [countryCode.name]: '',
  [mobile.name]: '',
  [jobTitle.name]: '',
  [jobRole.name]: '',
  [jobRoleSpecify.name]: '',
  [company.name]: '',
  [industrySector.name]: '',
  [industrySectorSpecify.name]: '',
  // [events.name]: [],
  [events.name]: false,
  // [accreditedMedia.name]: false,
  [recieveMoreInfo.name]: false,
  [conditions.name]: false,
};
