import React from 'react';
import Layout from '../../../components/layout';
import RegistrationFromGenerationFuture from '../../../components/registration-form/registration-form-generation-future';
import SEO from '../../../components/seo';

export default function RegisterGenerationFuture(props) {
  return (
    <Layout>
      <SEO lang='en' title='#GMIS2022 - Generation Future' />
      <RegistrationFromGenerationFuture
        imageName='registration-generation-future'
        imageAlt='registration-generation-future'
        source='Generation Future'
        page='generation-future'
      />
    </Layout>
  );
}
