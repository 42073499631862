import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  Typography,
  InputAdornment,
  MenuItem,
  FormHelperText,
  Box,
} from '@material-ui/core';

function SelectField({ label, data, specifyJobRoleCallback, ...rest }) {
  // const { label, data, ...rest } = props;
  const [field, meta] = useField({ label, data, ...rest });

  useEffect(() => {
    if (specifyJobRoleCallback) {
      if (field.value.name === 'Other') {
        specifyJobRoleCallback(true);
      }
      if (field.value.name !== 'Other') {
        specifyJobRoleCallback(false);
      }
    }
  });

  return (
    <>
      <Box mb={3}>
        <Box clone width={1} p={1}>
          <FormControl {...rest} error={meta.touched && meta.error ? true : false}>
            <Box clone ml={1}>
              <InputLabel>{label}</InputLabel>
            </Box>
            <Select
              {...field}
              value={field.value}
              endAdornment={
                <InputAdornment position='start'>
                  <Typography variant='body1'>*</Typography>
                </InputAdornment>
              }>
              {data.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{meta.touched && meta.error ? meta.error : ''}</FormHelperText>
          </FormControl>
        </Box>
      </Box>
    </>
  );
}

SelectField.defaultProps = {
  data: [],
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SelectField;
