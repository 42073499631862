import React from 'react';
import { useField } from 'formik';
import { TextField, InputAdornment, Typography, Box, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    textFieldLabelSpacing: {
      '& .MuiFormLabel-root': {
        marginLeft: '10px',
      },
    },
  })
);

export default function InputField(props) {
  const { errorText, ...rest } = props;
  const [field, meta] = useField(props);
  const classes = useStyles();

  return (
    <Box mb={3}>
      <Box clone width={1} p={2}>
        <TextField
          // value={props.myvalue}
          className={classes.textFieldLabelSpacing}
          error={meta.touched && meta.error ? true : false}
          helperText={meta.touched && meta.error ? meta.error : ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                <Typography variant='body1'>*</Typography>
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: props.limitchar ? props.limitchar : 1000,
          }}
          {...field}
          {...rest}
        />
      </Box>
    </Box>
  );
}
