import React, { useState } from 'react';
import { Button, Grid, CircularProgress, Box, useMediaQuery, useTheme } from '@material-ui/core';
import PersonalDetailsForm from './steppers/personal-details-form';
import { Formik, Form } from 'formik';
import checkoutFormModel from './formModel/checkout-form-model';
import validationSchema from './formModel/validation-schema';
import formInitialValues from './formModel/form-initial-values';
import { navigate } from 'gatsby';
import SectionContainerLayout from '../section-container-layout/section-container-layout';
import axios from 'axios';
import Fade from 'react-reveal/Fade';
import ImageContainer from '../image-container';
const { formId, formField } = checkoutFormModel;

export default function RegistrationFromGenerationFuture(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentValidationSchema = validationSchema[0];
  async function submitForm(values, actions) {
    const axiosPost = {
      salutation: values.salutation.name,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      country: values.country.name,
      nationality: values.nationality.name,
      countryCode: values.country.dialCode,
      mobile: values.mobile,
      source: props.source,
    };
    // console.log('axiosPost', axiosPost);
    // return;
    try {
      const REGData = {
        categoryCode: 'GUEWEBSIT',
        salutation: values.salutation.name,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        contact: values.mobile,
        countryCode: values.country.dialCode,
        country: values.country.name,
        nationality: values.nationality.name,
        fromWebsite: true,
        tag: props.source,
        isInfosalon: true,
      };
      axios
        .post(process.env.GATSBY_EMS_API_URL + 'guests/addfromwebsite', REGData)
        .then((response) => {})
        .catch((err) => {
          console.log('error', err);
        });

      //////////////////////////////////////

      const response = await axios.post(process.env.GATSBY_API_URL + 'register', axiosPost);
      actions.setSubmitting(false);

      if (response.status === 200) {
        navigate(`/registration/${props.page}/thankyou`);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleFormikSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  return (
    <SectionContainerLayout title='REGISTRATION'>
      <Grid container spacing={3}>
        {props.imageName && (
          <Grid item xs={12} sm={6}>
            <Fade bottom>
              <Box>
                <ImageContainer filename={props.imageName} altText={props.imageAlt || '#GMIS2021'} />
              </Box>
            </Fade>
          </Grid>
        )}
        <Grid item container xs={12} sm={props.imageName ? 6 : 12}>
          <Box width={1}>
            <React.Fragment>
              <Formik
                initialValues={formInitialValues}
                onSubmit={handleFormikSubmit}
                validationSchema={currentValidationSchema}>
                {({ isSubmitting, values }) => {
                  return (
                    <Form id={formId} autoComplete='off'>
                      {/* {getStepContent(activeStep, values)} */}
                      <PersonalDetailsForm allValues={values} formField={formField} />
                      <Box mt={5} textAlign='right'>
                        <Button
                          disabled={isSubmitting}
                          type='submit'
                          fullWidth={isMobile ? true : false}
                          variant='contained'
                          color='primary'>
                          Submit
                        </Button>
                        {isSubmitting && <CircularProgress size={24} />}
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </React.Fragment>
          </Box>
        </Grid>
      </Grid>
    </SectionContainerLayout>
  );
}
